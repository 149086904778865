import { AppGet, AppRestGet } from '@/utils/request';
import { IGetMapGeoResponse, IGetMapStatioinResponse, ISummary, IUserMapData } from './types';

// 获取世界地图Geo
export function getMapGeo() {
  return AppGet<IGetMapGeoResponse>('/sciplus/shao/ubas/base/picture/v1');
  // return AppGet<IGetMapGeoResponse>('/geo/world');
}

// 获取站点数据
export function getMapStation(stationGroup: string) {
  return AppRestGet<IGetMapStatioinResponse>(`/sciplus/shao/ubas/picture/v1/{groupname}`, {
    groupname: stationGroup
  });
  // return AppGet<IGetMapStatioinResponse>(`/geo/station`, { stationGroup });
}

// 获取站点数据
export function getSummary(stationGroup: string) {
  return AppGet<ISummary>(`/sciplus/shao/ubas/statis/v1`, { stationGroup });
}

export function getStationGroup() {
  return AppGet<Array<{ value: string; label: string }>>(
    '/sciplus/shao/ubas/get/sit/group/list/v1'
  );
}

export function getUserMapData() {
  return AppGet<Array<IUserMapData>>('/sciplus/shao/ubas/user/picture/v1');
}
