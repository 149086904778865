import { AppPost, AppGet, AppDownload, AppPostDownload} from '@/utils/request';
import { ICheckLoginRequest, ICheckLoginResponse, IGetUserInfoResponse, IChangePasswordParam } from './types';
import {absProductApi, globalApi} from '@/config/api'

// 获取表产品分类下拉框options
export function getProductClass(data?: any) {
  return AppGet(absProductApi.productClass, data);
}

// 获取产品数据
export function getProductsList(data: any) {
  return AppPost(absProductApi.productsList, data);
}

// 获取表格列头
export function getFields(data: any) {
  return AppGet(absProductApi.productFieldList, data);
}

// 选择全部 https://data.ccxa.cn/abs/durationAsset/allDealsId
export function getAllDealsId(data?: any) {
  return AppPost(absProductApi.allDealsId, data);
}

// 提取数据：获取相关证券 https://data.ccxa.cn/abs/product/relatedSecuritiesList
export function getRelatedSecuritiesList(data: any) {
  return AppPost(absProductApi.relatedSecuritiesList, data);
}
// 获取分析图表：市场发行规模-发行量  https://data.ccxa.cn/abs/product/issuanceCirculation
export function getIssuanceCirculation(data: any) {
  return AppPost(absProductApi.issuanceCirculation, data);
}

// 获取分析图表：市场发行规模-发现只数  https://data.ccxa.cn/abs/product/issuanceCount
export function getIssuanceCount(data: any) {
  return AppPost(absProductApi.issuanceCount, data);
}
// 获取分析图表：市场发行规模-发行分布  https://data.ccxa.cn/abs/product/issuanceDistribution
export function getIssuanceDistribution(data: any) {
  return AppPost(absProductApi.issuanceDistribution, data);
}

// 数据下载 https://data.ccxa.cn/abs/download/export
export function exportFile(data : any, fileName) {
  return AppPostDownload(absProductApi.absDownload, data, fileName);
}
