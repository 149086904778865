import React, { FC, useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';

import { RouterMain } from '@/router';
import MainHeader from './components/header';

import style from './index.module.less';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const LayoutMain: FC = (props: any) => {
  const [collapsed, setCollapsed] = useState(false);

  const [openMenu, setOpenMenu] = useState<string[]>([]);

  useEffect(() => {
    getOpenMenu();
    /* eslint-disable */
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (e: any) => {
    props.history.push(e.key);
  };

  const handleOpenChange = (openKeys: any) => {
    setOpenMenu(openKeys);
  };

  const getOpenMenu = () => {
    const currentPath = props.location.pathname;
    const temp = currentPath.split('/');
    if (temp.length > 3) {
      const ret = temp.slice(0, 3).join('/');
      setOpenMenu([ret]);
    } else {
      return [];
    }
  };

  return (
    <Layout className={style.layoutMain}>
      <Header className={style.header}>
        <MainHeader />
      </Header>
      <Layout className={style.layoutContent}>
        <Sider className={style.sider}>
          <Menu
            theme="dark"
            mode="inline"
            onClick={handleMenuClick}
            openKeys={openMenu}
            selectedKeys={props.location.pathname}
            onOpenChange={handleOpenChange}
            className={style['menu']}
          >
            {RouterMain.filter((item) => !item.noMenu).map((router: any) =>
              router?.child ? (
                <SubMenu
                  key={router.path}
                  title={
                    <span>
                      {router.icon ? <router.icon /> : null}
                      <span>{router.name}</span>
                    </span>
                  }
                >
                  {router.child
                    .filter((item: any) => !item.noMenu)
                    .map((item: any) => (
                      <Menu.Item key={item.path}>
                        {item.icon ? <item.icon /> : null}
                        <span>{item.name}</span>
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : (
                <Menu.Item key={router.path}>
                  {router.icon ? <router.icon /> : null}
                  <span>{router.name}</span>
                </Menu.Item>
              )
            )}
          </Menu>
        </Sider>
        <Content className={style.content}>
          <div className={style.container}>{props.children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutMain;
