import React from 'react';

import style from './index.module.less';

import loadingImg from './img/loading.gif';

export default function LoadingPage() {
  return (
    <div className={style.loadingMask}>
      <img src={loadingImg} className={style.loadingIcon} alt="loading..." />
    </div>
  );
}
