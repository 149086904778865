import { AppPost, AppGet, AppGetBlob, AppUpload } from '@/utils/request';
import { ICheckLoginRequest, ICheckLoginResponse, IGetUserInfoResponse, IChangePasswordParam } from './types';

// 登陆
export function checkLogin(data: ICheckLoginRequest) {
  return AppPost<ICheckLoginResponse>('/account/login', data);
}

// 根据token获取用户信息
export function getUserInfoByToken(token: string) {
  return AppGet<IGetUserInfoResponse>('/user/getUserInfo', { token });
}

// 根据token上传用户头像
export function uploadAvatar(data: any) {
  return AppUpload('/user/uploadPicture', data);
}

// 根据token获取用户头像
export function getAvatar() {
  return AppGetBlob<any>('/user/getProFilePicture');
}

// 退出登录
export function logout() {
  return AppGet<any>('/user/logout');
}

// 获取权限
export function getPermissions(params: any) {
  return AppGet<any>('/user/getPermissions', params);
}

// 更改密码
export function changePassword(params: IChangePasswordParam) {
  return AppPost<any>('/user/changePassword', params);
}

// 检验是否首次访问
export function checkFirstVisit(params: any) {
  return AppGet<any>('/common/checkFirstVisit', params);
}

// 标记不再需要引导页
export function markVisited(params: any) {
  return AppPost<any>('/common/markVisited', params);
}
// 获取验证码
export function getVerificationCode(data: any) {
  return AppGet<any>('/account/getVerificationCode', data);
}
// 手机号验证
export function userInputCode(data: any) {
  return AppGet<any>('/account/userInputCode', data);
}
// 注册
export function register(data: any) {
  return AppPost<any>('/account/register', data);
}
// 重置密码
export function retrievePassword(data: any) {
  return AppGet<any>('/account/retrievePassword', data);
}
// 发送邮件
export function sendEmail(data: any) {
  return AppGet<any>('/account/sendEmail', data);
}
// 邮箱查询
export function verificationEmail(data: any) {
  return AppGet<any>('/account/verificationEmail', data);
}