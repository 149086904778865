import { AppPost, AppGet, AppDownload } from '@/utils/request';

import { SurvivingBondList, BondInfoResponse } from './types';

import { homeApi } from '@/config/api';
export function getSurvivingBondList(params: any) {
  return AppPost<SurvivingBondList>('/debentureBase/list', params);
}
export function getBondInfo(params: any) {
  return AppGet<BondInfoResponse>('/debentureBase/getBaseData', params);
}
// 特殊条款
export function getSpecialProvisions(params: any) {
  return AppGet<BondInfoResponse>('/debAnalysis/getSpecialProvisions', params);
}
// 相同发行人
export function getIssuerToData(params: any) {
  return AppGet<BondInfoResponse>('/debentureBase/getIssuerToData', params);
}
// 增信措施
export function getDebGuarantee(params: any) {
  return AppGet<BondInfoResponse>('/debentureBase/getDebGuarantee', params);
}
//  债市日历  Get  /debentureBase/getDebCalendar
export function getDebCalendar(params: any) {
  return AppGet<BondInfoResponse>('/debentureBase/getDebCalendar', params);
}
//Get  /debentureBase/getTransactionDetails
export function getTransactionDetails(params: any) {
  return AppGet<any>('/debentureBase/getTransactionDetails', params);
}
// Get  /debentureBase/getCashingInformation
export function getCashingInformation(params: any) {
  return AppGet<any>('/debentureBase/getCashingInformation', params);
}
// 路径：http://192.168.15.93:9655/bond/getBondRatingResultOther
export function getBondRatingResultOther(params: any) {
  return AppGet<any>('/bond/getBondRatingResultOther', params);
}

// 路径：http://192.168.15.93:9655/bond/getBondBadNews
export function getBondBadNews(params: any) {
  return AppGet<any>('/bond/getBondBadNews', params);
}
// 请求地址： 127.0.0.1:9655?page=1&rows=1&debug



// /debentureBase/getOtherMarketDebt 交易市场信息
export function getOtherMarketDebt(params: any) {
  return AppGet<any>('/debentureBase/getOtherMarketDebt', params);
}

// 走势复盘-个券利差
export function getBondSpreadTrend(params: any) {
  return AppGet<any>('/trendReview/getBondSpreadTrend', params);
}
// 走势复盘-主体利差
export function getEnterpriseSpreadTrend(params: any) {
  return AppGet<any>('/trendReview/getEnterpriseSpreadTrend', params);
}
//excel 导出
export function downTransactionDetailsExcel(params: any, ExcelName: any, callback?: any) {
  return AppDownload('/debentureBase/downTransactionDetailsExcel', params, ExcelName, callback);
}