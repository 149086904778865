import { AppPost, AppGet} from '@/utils/request';

import {
  List
} from './types';

import { homeApi } from '@/config/api';

//银行基础信息
//get  /smartFinance/bank/getList
export function getList(params: any) {
  return AppGet<List>('/smartFinance/bank/getList', params);
}

//银行监管指标
//get  /smartFinance/bank/getRegulatoryList
export function getRegulatoryList(params: any) {
  return AppGet<List>('/smartFinance/bank/getRegulatoryList', params);
}

//银行存贷款指标
//get  /smartFinance/bank/getDepositAndLoanList
export function getDepositAndLoanList(params: any) {
  return AppGet<List>('/smartFinance/bank/getDepositAndLoanList', params);
}

//证券公司基础信息
//get  /smartFinance/securities/getList
export function securities_getList(params: any) {
  return AppGet<List>('/smartFinance/securities/getList', params);
}

//证券公司监管指标
//get  /smartFinance/securities/getRegulatoryList
export function securities_getRegulatoryList(params: any) {
  return AppGet<List>('/smartFinance/securities/getRegulatoryList', params);
}


//财报年份
//get  /smartFinance/getFinYear
export function getFinYear(params: any) {
  return AppGet<List>('/smartFinance/getFinYear', params);
}

//银行财报年份
//get  /smartFinance/getBankFinYear
export function getBankFinYear(params: any) {
  return AppGet<List>('/smartFinance/getBankFinYear', params);
}
//证券财报年份
//get  /smartFinance/getSecuritiesFinYear
export function getSecuritiesFinYear(params: any) {
  return AppGet<List>('/smartFinance/getSecuritiesFinYear', params);
}
