import { AppPost, AppGet, AppPreView } from '@/utils/request';
import Cookies from 'js-cookie';
export function getReportList(params: any) {
  return AppGet<any>('/researchReport/getResearchReports', params);
}

export function getCMFReport(params: any) {
  return AppGet<any>('/researchReport/getCMFReport', params);
}
export function openPDF(params: any) {
  window.open('/api/minioFile/previewPDF?fileName=' + params.fileName + '&token=' + Cookies.get('_fits_client_'));
}
export function getCMFopenPDF(params: any) {
  window.open('/api/minioFile/previewPDFCMF?fileName=' + params.fileName + '&token=' + Cookies.get('_fits_client_'));
}