import { AppPost, AppGet, AppDownload, AppPostDownload} from '@/utils/request';
import {absProductApi} from '@/config/api'

// 获取表产品分类下拉框options
export function getProductClass(data?: any) {
  return AppGet(absProductApi.productClass, data);
}

// 获取归集现金流
export function getList(data: any) {
  return AppPost(absProductApi.creditMeasuresList, data);
}


// 选择全部 https://data.ccxa.cn/abs/durationAsset/allDealsId
export function getAllIds(data?: any) {
  return AppPost(absProductApi.creditMeasuresAllIds, data);
}


// 数据下载 https://data.ccxa.cn/abs/download/export
export function exportFile(data : any, fileName) {
  return AppPostDownload(absProductApi.absDownload, data, fileName);
}


