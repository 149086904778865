import { AppPost, AppGet, AppDownload } from '@/utils/request';

import { QeList, IGetUserInfoResponse } from './types';

import { homeApi } from '@/config/api';
export function QErateList(params: any) {
  return AppGet<QeList>('/company/getList', params);
}
export function QEindustry(params: any) {
  return AppGet<QeList>('/common/getTradeTypeList', params);
}
export function QEgetIndustryInfo(params: any) {
  return AppGet<QeList>('/common/getIndustryInfo', params);
}
export function QEregionTree(params: any) {
  //let _e=localStorage.getItem('regionTree');
  // if(_e)return JSON.parse(_e);
  const e = AppGet<QeList>('/common/regionTree', params);
  return e;
}
export function downloadExcel(params: any, ExcelName: any, callback?: any) {
  const e = AppDownload('/compProfile/exportExcel', params, ExcelName, callback);
  return e;
}

export function QeExcel(params: any, ExcelName: any) {
  const e = AppDownload('/company/export', params, ExcelName);
  return e;
}