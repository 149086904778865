import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AppThunk, RootState } from '@/store';
import { getToken, setToken as setTokenInCookie } from '@/utils/auth';
import { RegionalSpreadApi, industrySpreadApi } from '@/services';
import { IGetUserInfoResponse } from '@/services/modules/app/types';
interface AppState {
  regionCurves: any;
  industryCurves: any;
}

const initialState: AppState = {
  regionCurves: null, // 区域利差曲线列表
  industryCurves: null // 行业利差曲线列表
};

export const appSlice = createSlice({
  name: 'regionalSpread',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers.It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setRegionCurveList: (state, action: PayloadAction<string>) => {
      state.regionCurves = action.payload;
    },
    setIndustryCurveList: (state, action: PayloadAction<string>) => {
      state.industryCurves = action.payload;
    }
  }
});

export const { setRegionCurveList,setIndustryCurveList } = appSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// 区域利差曲线数据
export const getRegionCurveList =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      const res = await RegionalSpreadApi.curveList({});
      if (res.return_code === 0) {
        dispatch(setRegionCurveList(res));
        return res
      }
    } catch (err: any) {
      console.log(err);
    }
    };

  // 行业利差曲线数据
  export const getIndustryCurveList =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      const res = await industrySpreadApi.curveList({});
      if (res.return_code === 0) {
        dispatch(setIndustryCurveList(res));
        return res
      }
    } catch (err: any) {
      console.log(err);
    }
  };
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectRegionCurves = (state: any) => state.regionalSpread.regionCurves;
export const selectIndustryCurves = (state: any) => state.regionalSpread.industryCurves;

export default appSlice.reducer;
