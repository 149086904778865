import React, { useEffect, useState } from 'react';
import style from './index.module.less';

// 用户个人信息
const UserInfo = (props: any) => {
  const { userInfo } = props
  return <ul className={style.userInfo}>
    <li><label>用户名</label><span>{userInfo?.name || '--'}</span></li>
    <li><label>所属机构</label><span>{userInfo?.institution || '--'}</span></li>
    <li><label>所属部门</label><span>{userInfo?.department || '--'}</span></li>
    <li><label>绑定邮箱</label><span>{userInfo?.email || '--'}</span></li>
    <li><label>绑定手机</label><span>{userInfo?.phone || '--'}</span></li>
    <li><label>账号权限</label><span>{userInfo?.roleList[0]?.name || '--'}</span></li>
    <li><label>注册时间</label><span>{userInfo?.createDate?.split('T')[0] || '--'}</span></li>
    <li><label>失效时间</label><span>{userInfo?.expiryDate?.split('T')[0] || '--'}</span></li>
  </ul>
}

export default UserInfo;
