import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { AppThunk, RootState } from '@/store';
import { getToken, setToken as setTokenInCookie, removeToken } from '@/utils/auth';
import { appApi } from '@/services';
import { IGetUserInfoResponse } from '@/services/modules/app/types';
interface AppState {
  token: string;
  errMsg: string;
  userInfo: any;
  theme: string;
  RoutingAddress: any;
  checkedTag: any;
  refreshData: any;
  avatar: any; // 用户头像
  permission: any; // 用户权限
  visited: boolean; // 标记是否需要引导页
  WisdomType: any;//城投页面选择
  NoAccessFlag: any;
}

const initialState: AppState = {
  token: getToken(),
  errMsg: '', // 全局错误提示
  userInfo: {},
  theme: 'dark',
  RoutingAddress: [],
  checkedTag: '',
  refreshData: '11',
  permission: null,
  avatar: '',
  visited: false,
  WisdomType: "ct",
  NoAccessFlag: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = '';
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
    },
    clearErrMsg: (state) => {
      state.errMsg = '';
    },
    setUserInfo: (state, action: PayloadAction<IGetUserInfoResponse>) => {
      state.userInfo = action.payload;
    },
    setPermission: (state, action: PayloadAction<IGetUserInfoResponse>) => {
      state.permission = action.payload;
    },
    setAvatar: (state, action: PayloadAction<string>) => {
      state.avatar = action.payload;
    },
    setTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    setWisdomType: (state, action: PayloadAction<any>) => {
      state.WisdomType = action.payload;
    },
    setRoutingAddress: (state, action: PayloadAction<any>) => {
      state.RoutingAddress = action.payload;
      localStorage.setItem('RoutingAddress', JSON.stringify(action.payload));
    },
    setCheckedTag: (state, action: PayloadAction<any>) => {
      state.checkedTag = action.payload;
      localStorage.setItem('checkedTag', action.payload);
    },
    setRefreshData: (state, action: PayloadAction<any>) => {
      state.refreshData = action.payload;
    },
    setVisited: (state, action: PayloadAction<any>) => {
      state.visited = action.payload;
    },
    setNoAccessFlag: (state, action: PayloadAction<any>) => {
      state.NoAccessFlag = action.payload;
    },
  }
});

export const {
  setToken,
  clearToken,
  setErrMsg,
  clearErrMsg,
  setUserInfo,
  setPermission,
  setAvatar,
  setTheme,
  setRoutingAddress,
  setCheckedTag,
  setRefreshData,
  setVisited,
  setWisdomType,
  setNoAccessFlag
} = appSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const checkLogin =
  (data: any): AppThunk =>
    async (dispatch) => {
      try {
        const userData: any = await appApi.checkLogin(data);
        console.log(userData)
        if (userData.return_code !== '0') {
          return message.error(userData.return_msg)
        }
        setTokenInCookie(userData.data.token);
        dispatch(setToken(userData.data.token));
      } catch (err: any) {
        message.error("账号或密码错误")
        dispatch(setErrMsg(err.toString()));
      }
    };


export const getUserInfo =
  (token: string): AppThunk =>
    async (dispatch) => {
      try {
        const res: any = await appApi.getUserInfoByToken(token);
        if (res.return_code !== '0') {
          return message.error(res.return_msg)
        }
        dispatch(setUserInfo(res));
      } catch (err: any) {
        dispatch(setErrMsg(err.toString()));
      }
    }

export const getPermissions =
  (token?: string): AppThunk =>
    async (dispatch) => {
      try {
        const res: any = await appApi.getPermissions(token);
        if (res.return_code !== '0') {
          return message.error(res.return_msg)
        }
        // console.log('premisaaion:' , res)
        dispatch(setPermission(res?.data));
      } catch (err: any) {
        dispatch(setErrMsg(err.toString()));
      }
    }
// 获取用户头像
export const getAvatar =
  (): AppThunk =>
    async (dispatch) => {
      try {
        const res: any = await appApi.getAvatar();
        // console.log(res)
        const blob = new Blob([res], { type: "image/png" }); //类型一定要写！！！
        const reader: any = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          // console.log(reader.result)
          dispatch(setAvatar(reader.result))
        }
      } catch (err: any) {
        dispatch(setErrMsg(err.toString()));
      }
    };

// 退出登录
export const logout =
  (): AppThunk =>
    async (dispatch) => {
      try {
        await appApi.logout();
        const { origin } = window.location;
        window.location.href = `${origin}/login`
        clearToken()
        removeToken()
        localStorage.removeItem('checkedTag')
        localStorage.removeItem('RoutingAddress')
        localStorage.setItem('pageName', 'homePage')
      } catch (err: any) {
        dispatch(setErrMsg(err.toString()));
      }
    };

// 检验是否首次访问
export const checkFirstVisit =
  (params): AppThunk =>
    async (dispatch) => {
      try {
        const res = await appApi.checkFirstVisit(params);
        if (res.return_code !== '0') {
          return message.error(res.return_msg)
        }
        dispatch(setVisited(res?.data));
      } catch (err: any) {
        dispatch(setErrMsg(err.toString()));
      }
    };

// 标记不在需要引导页
export const markVisited =
  (params): AppThunk =>
    async (dispatch) => {
      try {
        const res = await appApi.markVisited(params);
        if (res.return_code !== '0') {
          return message.error(res.return_msg)
        }
        dispatch(setVisited(false));
      } catch (err: any) {
        dispatch(setErrMsg(err.toString()));
      }
    };
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectToken = (state: RootState) => state.app.token;
export const selectErrMsg = (state: RootState) => state.app.errMsg;
export const selectUserInfo = (state: RootState) => state.app.userInfo;
export const selectPermission = (state: RootState) => state.app.permission;
export const selectAvatar = (state: RootState) => state.app.avatar;
export const selectTheme = (state: RootState) => state.app.theme;
export const selectRoutingAddress = (state: RootState) => state.app.RoutingAddress;
export const selectCheckedTag = (state: RootState) => state.app.checkedTag;
export const selectRefreshData = (state: RootState) => state.app.refreshData;
export const selectVisited = (state: RootState) => state.app.visited;
export const selectWisdomType = (state: RootState) => state.app.WisdomType;
export const selectNoAccessFlag = (state: RootState) => state.app.NoAccessFlag;

export default appSlice.reducer;
