import { AppPost, AppGet, AppDownload } from '@/utils/request';

import { QedetailData, QeList } from './types';

export function Qedetail(params: any) {
  return AppGet<QedetailData>('/company/detail', params);
}

export function QeFinancialOverview(params: any) {
  return AppGet<QedetailData>('/operatingFinance/getFinIndicatorList', params);
}

export function QeRevenueOverview(params: any) {
  return AppGet<QedetailData>('/operatingFinance/getIncomeRecords', params);
}

export function QegetTimeLine(params: any) {
  return AppGet<QedetailData>('/operatingFinance/getTimeLine', params);
}

export function CreditAnalysis(params: any) {
  return AppGet<QedetailData>('/platform/creditAnalysis', params);
}

export function QeWarning(params: any) {
  return AppGet<QedetailData>('/warningMonitoring/qeWarning', params);
}
export function getDistribution(params: any) {
  return AppGet<QedetailData>('/juche/getDistribution', params);
}
export function getListForAnalysis(params: any) {
  return AppGet<QeList>('/juche/getListForAnalysis', params);
}
export function getTypeList(params: any) {
  return AppGet<QedetailData>('/juche/getTypeList', params);
}
export function getDebDetail(params: any) {
  return AppGet<QedetailData>('/debAnalysis/getDebDetail', params); //全部债券
}
export function getMarketTransactions(params: any) {
  return AppGet<QeList>('/debentureBase/getMarketTransactions', params); //市场成交
}
export function qeTrend(params: any) {
  return AppGet<QedetailData>('/warningMonitoring/qeTrend', params);
}
export function ratingChange(params: any) {
  return AppGet<QeList>('/compProfile/ratingChange', params);
}

export function getDebDetailEcharts(params: any) {
  return AppGet<QedetailData>('/debAnalysis/analyzeExpirationPressure', params); //到期压力图
}
export function getFinancialAnalysis(params: any) {
  return AppGet<QeList>('/enterprise/getFinancialAnalysis', params); //财务概览
}
export function platform_getFinancialAnalysis(params: any) {
  return AppGet<QeList>('/platform/getFinancialAnalysis', params); //财务后三个表
}
export function debAnalysisTable(params: any) {
  return AppGet<QeList>('/debAnalysis/analyzeExpirationPressureTable', params); //到期压力表
}
export function debAnalysisDetails(params: any) {
  return AppGet<QeList>('/debAnalysis/analyzeExpirationPressureDetails', params); //到期压力表明细
}
export function analyzeHistoricalFinancingle(params: any) {
  return AppGet<QeList>('/debAnalysis/analyzeHistoricalFinancingle', params); //历史融资 图谱 列表
}
export function analyzeHistoricalFinancingleDetails(params: any) {
  return AppGet<QeList>('/debAnalysis/analyzeHistoricalFinancingleDetails', params); //历史融资 图谱 列表
}
export function DMquota(params: any) {
  return AppGet<QeList>('/finGuarantee/getRegistrationLimit', params); //获取DCM融资额度
}
export function getBankCreditData(params: any) {
  return AppGet<QeList>('/finGuarantee/getBankCreditData', params); //获取银行授信图表
}
export function getBankCreditDetail(params: any) {
  return AppGet<QeList>('/finGuarantee/getBankCreditDetail', params); //获取银行授信表格
}
export function getBankLoansData(params: any) {
  return AppGet<QeList>('/finGuarantee/getBankLoansData', params);
}
export function getReceivablesFinancing(params: any) {
  return AppGet<QeList>('/finGuarantee/getReceivablesFinancing', params);
}

export function getFinanceLease(params: any) {
  return AppGet<QeList>('/finGuarantee/getFinanceLease', params);
}
export function getTrustFinancing(params: any) {
  return AppGet<QeList>('/finGuarantee/getTrustFinancing', params);
}
export function getGuaranteeData(params: any) {
  return AppGet<QeList>('/finGuarantee/getGuaranteeData', params);
}
export function getAnnouncementDisclosure(params: any) {
  return AppGet<QeList>('/enterprise/getAnnouncementDisclosure', params);
}

export function getGuaranteeStatistics(params: any) {
  return AppGet<QeList>('/finGuarantee/getGuaranteeStatistics', params); //对外担保统计
}
export function makeAbargain(params: any, ExcelName: any) {
  const e = AppDownload('/company/export', params, ExcelName);
  return e;
}
export function BondDetailExcel(params: any, ExcelName: any, callback: any) {
  const e = AppDownload('/debAnalysis/exportDebDetail', params, ExcelName, callback);
  return e;
}
export function MarketTransactionExcel(params: any, ExcelName: any, callback?: any) {
  const e = AppDownload('/debentureBase/downMarketTransactionsExcel', params, ExcelName, callback);
  return e;
}

export function getSpecialProvisions(params: any) {
  return AppGet<QeList>('/debAnalysis/getSpecialProvisions', params);
}

export function warning_chart(params: any) {
  return AppGet<QeList>('/early-warning/chart', params); //预警监控图表
}
export function warning_list(params: any) {
  return AppGet<QeList>('/early-warning/list', params); //财务指标预警
}
export function FocusInfo(params: any) {
  return AppPost<QeList>('/invest/focusInfo', params);//查看组合
}

export function groupCreate(params: any, callback: any) {
  AppGet('/invest/groupCreate', params, function (data: any) {
    callback(data);
  });
}
export function FocusModify(params: any, callback: any) {
  AppPost('/invest/focusModify', params, function (data: any) {
    callback(data);
  });
}
export function getPoolInfo(params: any, callback: any) {
  AppGet('/invest/poolInfo', params, function (data: any) {
    callback(data);
  });
}

export function groupInfo(params: any) {
  return AppGet<QeList>('/invest/groupInfo', params);//查看组合
}

export function addEntPool(params: any) {
  return AppGet<QeList>('/invdSearch/addEntPool', params);//查看组合
}
export function getFinancingDistribution(params: any) {
  return AppGet<QeList>('/debAnalysis/getFinancingDistribution', params);//查看组合
}

export function getRateTrend(params: any) {
  return AppGet<QeList>('/debAnalysis/getRateTrend', params);//查看组合
}