import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { LayoutCommon, LayoutSimple } from '@/layout';
import { RouterCommon, RouterMain, RouterBase } from '@/router';
import PrivateRoute from '@/components/PrivateRoute';
import style from './index.module.less';

const App = () => {
  const renderRouter = (router: any, routeProps: any) => {
    return router.child ? (
      router.child.map((item: any) => renderRouter(item, routeProps))
    ) : (
      <PrivateRoute
        exact={!!router.exact}
        key={router.path}
        path={router.path}
        {...routeProps}
        render={(props: any) => <router.component {...props} />}
      />
    );
  };

  return (
    <div className={style.app}>
      <Router>
        <Switch>
          <Route
            path="/alterLogin"
            render={(routeProps) => (
              <Switch>
                {RouterBase.map((router) => renderRouter(router, routeProps))}
                <Redirect to="/login" from="/" exact />
                <Redirect to="/home" from="/" exact />
                <Redirect to="/404" />
              </Switch>
            )}
          />
          <Route
            path="/login"
            render={(routeProps: any) => (
              <LayoutSimple {...routeProps}>
                <Switch>
                  {RouterBase.map((router) => {
                    return router.child && router.child.length > 0 ? (
                      router.child.map((item: any) => (
                        <Route
                          exact={!!item.exact}
                          key={item.path}
                          path={item.path}
                          component={item.component}
                        />
                      ))
                    ) : (
                      <Route
                        exact={!!router.exact}
                        key={router.path}
                        path={router.path}
                        component={router.component}
                      />
                    );
                  })}
                  <Redirect to="/home" />
                </Switch>
              </LayoutSimple>
            )}
          />
          <Route
            path="/"
            render={(routeProps: any) => (
              <LayoutCommon {...routeProps}>
                <Switch>
                  {RouterCommon.map((router) => {
                    return router.child && router.child.length > 0 ? (
                      router.child.map((item: any) => (
                        <Route
                          exact={!!item.exact}
                          key={item.path}
                          path={item.path}
                          component={item.component}
                        />
                      ))
                    ) : (
                      <Route
                        exact={!!router.exact}
                        key={router.path}
                        path={router.path}
                        component={router.component}
                      />
                    );
                  })}
                  <Redirect exact to="/login" from="/" />
                  <Redirect exact to="/home" from="/" />
                  <Redirect to="/home" />
                </Switch>
              </LayoutCommon>
            )}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
