import React from 'react';

import logo from './imgs/logo.png';

import css from './index.module.less';

function BlankHeader() {
  return (
    <div className={css['blank-header']}>
      <img src={logo} className={css['logo']} alt="" />
      <div className={css['title']}>FITS</div>
    </div>
  );
}

export default BlankHeader;
