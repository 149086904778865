import { AppPost, AppGet, AppDownload, AppPostDownload } from '@/utils/request';

import { detailData, List } from './types';

export function FeatureList(params: any) {
  return AppPost<detailData>('/features/list', params);
}
export function abs_filed_mapping(params: any) {
  return AppGet<detailData>('/absFiled/mapping', params);
}

export function productClass(params: any) {
  return AppGet<detailData>('/common/productClass', params);
}
export function allDealId(params: any) {
  return AppPost<detailData>('/features/allDealId', params);
}
export function exportFile(data: any, fileName: any) {
  return AppPostDownload('/features/export', data, fileName);
}
export function assetDistributionList(params: any) {
  return AppGet<detailData>('/assetDistribution/assetDistributionList', params);
}
export function idsList(params: any) {
  return AppGet<detailData>('/assetDistribution/idsList', params);
}
export function listDetail(params: any) {
  return AppPost<detailData>('/assetDistribution/listDetail', params);
}

export function exportList(data: any, fileName: any) {
  return AppPostDownload('/download/export', data, fileName);
}

export function bondWholeMarket(data: any) {
  return AppPost<detailData>('/bonds/bondWholeMarket', data);
}

export function allBondsId(data: any) {
  return AppPost<detailData>('/bonds/allBondsId', data);
}
export function relatedDeals(data: any) {
  return AppPost<detailData>('/bonds/relatedDeals', data);
}
export function bondsStatistics(data: any) {
  return AppPost<detailData>('/bonds/bondsStatistics', data);
}
export function initLayeringRatio(data: any) {
  return AppPost<detailData>('/bonds/initLayeringRatio', data);
}

export function orgRatingList(data: any) {
  return AppPost<detailData>('/bonds/orgRatingList', data);
}
export function issueRateByRating(data: any) {
  return AppPost<detailData>('/bonds/issueRateByRating', data);
}

export function bondDurationList(params: any) {
  return AppGet<detailData>('/bondDuration/bondDurationList', params);
}

export function bondDurationidsList(params: any) {
  return AppGet<detailData>('/bondDuration/idsList', params);
}

export function selectedBonds(params: any) {
  return AppPost<detailData>('/bondDuration/selectedBonds', params);
}

export function selectedBondsDetails(params: any) {
  return AppPost<detailData>('/bondDuration/selectedBondsDetails', params);
}

export function securities_List(params: any) {
  return AppPost<detailData>('/securities/list', params);
}
export function securities_allDealId(params: any) {
  return AppPost<detailData>('/securities/allDealId', params);
}

export function bondInventsList(params: any) {
  return AppPost<detailData>('/BondInvents/bondInventsList', params);
}

export function getAllids(params: any) {
  return AppPost<detailData>('/BondInvents/getAllids', params);
}

export function calcAccountDate(params: any) {
  return AppGet<detailData>('/BondInvents/calcAccountDate', params);
}

export function analysisByMarket(params: any) {
  return AppPost<detailData>('/BondInvents/analysisByMarket', params);
}

export function analysisByExchange(params: any) {
  return AppPost<detailData>('/BondInvents/analysisByExchange', params);
}

export function analysisByBondType(params: any) {
  return AppPost<detailData>('/BondInvents/analysisByBondType', params);
}

export function analysisByProduct(params: any) {
  return AppPost<detailData>('/BondInvents/analysisByProduct', params);
}