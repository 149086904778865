import React, { useEffect, FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { selectToken } from '@/store/modules/app';
import { getToken } from '@/utils/auth';

export interface PrivateRouteProps {
  render: (props: any) => ReactNode;
}
type renderType = (props: any) => any;

const PrivateRoute: FC<any> = ({ render, ...rest }: any) => {
  const { pathname, search } = useLocation();
  const token = useSelector(selectToken);

  const history = useHistory();
  useEffect(() => {
    if (!token) {
      history.push('/login', { from: pathname + search });
    }
  }, [history, pathname, search, token]);

  const renderFunc: renderType = (props: any) => {
    return token ? (
      render(props)
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: pathname + search
          }
        }}
      />
    );
  };

  return <Route {...rest} render={(props) => renderFunc(props)} />;
};

export default PrivateRoute;
