import { AppPost, AppGet, AppDownload } from '@/utils/request';

import { Invest, List } from './types';

export function embodimentCompany(params: any, callback: any) {
  AppPost('/comEs/embodimentCompany', params, function (data: any) {
    callback(data);
  });
}
export function insertData(params: any, url: any, callback: any) {
  AppPost('/invdProgramme/insertData?isTemp=' + url, params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_list(params: any, callback: any) {
  AppGet('/invdProgramme/list', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_deleteData(params: any, callback: any) {
  AppGet('/invdProgramme/deleteData', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_updateName(params: any, callback: any) {
  AppGet('/invdProgramme/updateName', params, function (data: any) {
    callback(data);
  });
}
export function comEs_queryBonds(params: any, callback: any) {
  AppPost('/comEs/queryBonds', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_enterpriseList(params: any, callback: any) {
  AppGet('/invdSearch/enterpriseList', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_debtList(params: any, callback: any) {
  AppGet('/invdSearch/debtList', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_enterpriseRemove(params: any, callback: any) {
  AppGet('/invdSearch/enterpriseRemove', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_debtRemove(params: any, callback: any) {
  AppGet('/invdSearch/debtRemove', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_enterpriseRemoveData(params: any, callback: any) {
  AppGet('/invdSearch/enterpriseRemoveData', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_debtRemoveData(params: any, callback: any) {
  AppGet('/invdSearch/debtRemoveData', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_enterpriseRecovery(params: any, callback: any) {
  AppGet('/invdSearch/enterpriseRecovery', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_debtRecovery(params: any, callback: any) {
  AppGet('/invdSearch/debtRecovery', params, function (data: any) {
    callback(data);
  });
}
export function invdSearch_enterpriseChangeData(params: any, callback: any) {
  AppGet('/invdSearch/enterpriseChangeData', params, function (data: any) {
    callback(data);
  });
}
export function invdSearch_debtChangeData(params: any, callback: any) {
  AppGet('/invdSearch/debtChangeData', params, function (data: any) {
    callback(data);
  });
}

export function invdSearch_exportExcel(params: any, ExcelName: any, callback: any) {
  const e = AppDownload('/invdSearch/exportExcel', params, ExcelName, callback);
  return e;
}
export function invdSearch_clearRemoveData(params: any, callback: any) {
  AppGet('/invdSearch/clearRemoveData', params, function (data: any) {
    callback(data);
  });
}
export function invdProgramme_clearTempData(params: any, callback: any) {
  AppGet('/invdProgramme/clearTempData', params, function (data: any) {
    callback(data);
  });
}
// export function FocusModify(params: any, callback: any) {
//   AppPost('/invest/focusModify', params, function (data: any) {
//     callback(data);
//   });
// }
// export function getPoolInfo(params: any, callback: any) {
//   AppGet('/invest/poolInfo', params, function (data: any) {
//     callback(data);
//   });
// }