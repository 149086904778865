
export const API_ADDRESS = process.env.ABS_API_ADDRESS; // API前缀
import appConfig from '@/config';

const absProductApi: any = {
  // 产品大全API
  // productClass: `${appConfig.absUrl}/abs/common/productClass`, // 产品分类下拉框options
  productClass: `/common/productClass`, // 产品分类下拉框options
  productFieldList: `/absFiled/mapping`,
  productsList: `/product/productsList`, // 产品例表
  allDealsId: `/product/allDealsId`, // 选择全部
  relatedSecuritiesList: `/product/relatedSecuritiesList`, // 获取相关证券
  issuanceCirculation: `/product/issuanceCirculation`, // 获取分析图表：市场发行规模-发行量
  issuanceCount: `/product/issuanceCount`, // 获取分析图表：市场发行规模-发现只数
  issuanceDistribution: `/product/issuanceDistribution`, // 获取分析图表：市场发行规模-发行分布 
  absDownload: `/download/export`, // 数据下载
  // 归集现金流API
  cashflowList: `/cashFlow/cashFlowList`, //ABS归集现金流列表数据
  allCashflowIds: `/cashFlow/getCashFlowIds`, // 获取全部筛选的归集现金流ID
  // 资产存续期-债权类
  assetClaimsList: `/durationAsset/assetClaimsList`,
  staticPool: `/durationAsset/staticPool`,
  scaleDiagram: `/durationAsset/scaleDiagram`,
  shortNameList: `/durationAsset/shortNameList`,
  alldurationAssetIds: `/durationAsset/allDealsId`,
  // 资产存续期-不良类
  nonPerformList: `/nonperform/nonPerformList`,
  getAllids: `/nonperform/getAllids`,
  // 增信措施
  creditMeasuresList: `/creditEnhancement/list`,
  creditMeasuresAllIds:  `creditEnhancement/getIds`
};
export default absProductApi;
