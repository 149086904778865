import React from 'react';
import {
  DashboardOutlined,
  SettingOutlined,
  ToolOutlined,
  TeamOutlined,
  CompassOutlined,
  RocketOutlined
} from '@ant-design/icons';
import loadable from '@loadable/component';

import Loading from '@/components/Loading';

// const PersonCenter = loadable(() => import('@/pages/person-center'), {
//   fallback: <Loading />
// });

// const Home = loadable(() => import('@/pages/home'), {
//   fallback: <Loading />
// });
const DashBoard = loadable(() => import('@/pages/dashboard'), {
  fallback: <Loading />
});

// const UserList = loadable(() => import('@/pages/user-list'), {
//   fallback: <Loading />
// });

// const UserDataList = loadable(() => import('@/pages/user-data-list'), {
//   fallback: <Loading />
// });

// const UserDataDetail = loadable(() => import('@/pages/user-data-detail'), {
//   fallback: <Loading />
// });

// const UserDataOverviewList = loadable(() => import('@/pages/user-data-overview-list'), {
//   fallback: <Loading />
// });

// const StationDataList = loadable(() => import('@/pages/station-data-list'), {
//   fallback: <Loading />
// });

// const UserDataHistoryList = loadable(() => import('@/pages/user-data-history-list'), {
//   fallback: <Loading />
// });

// const StationDataDetail = loadable(() => import('@/pages/station-data-detail'), {
//   fallback: <Loading />
// });

// const GroupMgt = loadable(() => import('@/pages/group-mgt'), {
//   fallback: <Loading />
// });

const routerMain = [
  // {
  //   path: '/home',
  //   name: '首页',
  //   component: Home,
  //   icon: DashboardOutlined,
  //   exact: true,
  //   noMenu: false
  // },
  {
    path: '/admin/dashboard',
    name: 'DashBoard',
    component: DashBoard,
    icon: DashboardOutlined,
    exact: true,
    noMenu: false
  }
  // {
  //   path: '/admin/user-data',
  //   name: '用户分析',
  //   icon: ToolOutlined,
  //   noMenu: false,
  //   child: [
  //     {
  //       path: '/admin/user-data/overview',
  //       name: '实时用户在线统计',
  //       component: UserDataOverviewList,
  //       icon: TeamOutlined,
  //       exact: true,
  //       noMenu: false
  //     },
  //     {
  //       path: '/admin/user-data/list',
  //       name: '实时用户分析列表',
  //       component: UserDataList,
  //       icon: TeamOutlined,
  //       exact: true,
  //       noMenu: false
  //     },
  //     {
  //       path: '/admin/user-data/detail/:id',
  //       name: '实时用户分析详情',
  //       component: UserDataDetail,
  //       icon: TeamOutlined,
  //       noMenu: true
  //     },
  //     {
  //       path: '/admin/user-data-history/list',
  //       name: '历史用户分析列表',
  //       component: UserDataHistoryList,
  //       icon: TeamOutlined,
  //       exact: true,
  //       noMenu: false
  //     }
  //   ]
  // },
  // {
  //   path: '/admin/station-data',
  //   name: '观测站分析',
  //   icon: ToolOutlined,
  //   noMenu: false,
  //   child: [
  //     {
  //       path: '/admin/station-data/list',
  //       name: '观测站分析列表',
  //       component: StationDataList,
  //       icon: TeamOutlined,
  //       exact: true,
  //       noMenu: false
  //     },
  //     {
  //       path: '/admin/station-data/detail/:id',
  //       name: '观测站分析详情',
  //       component: StationDataDetail,
  //       icon: TeamOutlined,
  //       noMenu: true
  //     }
  //   ]
  // },
  // {
  //   path: '/admin/user',
  //   name: '用户管理',
  //   icon: ToolOutlined,
  //   noMenu: false,
  //   child: [
  //     {
  //       path: '/admin/user/list',
  //       name: '用户列表',
  //       component: UserList,
  //       icon: TeamOutlined,
  //       exact: true,
  //       noMenu: false
  //     }
  //   ]
  // },
  // {
  //   path: '/admin/config',
  //   name: '配置管理',
  //   icon: ToolOutlined,
  //   noMenu: false,
  //   child: [
  //     {
  //       path: '/admin/config/group',
  //       name: '站点分组',
  //       component: GroupMgt,
  //       icon: TeamOutlined,
  //       exact: true,
  //       noMenu: false
  //     }
  //   ]
  // }
];

export default routerMain;
