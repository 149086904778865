import React from 'react';

import loadable from '@loadable/component';

import Loading from '@/components/Loading';

const Login = loadable(() => import('@/pages/login'), {
  fallback: <Loading />
});
const AlterLogin = loadable(() => import('@/pages/alterLogin'), {
  fallback: <Loading />
});

const GetBack = loadable(() => import('@/pages/getBack'), {
  fallback: <Loading />
});

const EmailGetBack = loadable(() => import('@/pages/EmailGetBack'), {
  fallback: <Loading />
});
const NotFound = loadable(() => import('@/pages/error/404'), {
  fallback: <Loading />
});

const RouterBase = [
  {
    path: '/login',
    component: Login,
    exact: true,
    nomenu: true,
    child: []
  },
  {
    path: '/login/alterLogin',
    component: AlterLogin,
    exact: true,
    nomenu: true,
    child: []
  },
  {
    path: '/login/retrieve-password',
    component: GetBack,
    exact: true,
    nomenu: true,
    child: []
  },
  {
    path: '/login/retrieve-Email-password/:token/:Email',
    component: EmailGetBack,
    exact: true,
    nomenu: true,
    child: []
  },

  // {
  //   path: '/404',
  //   component: NotFound,
  //   exact: true,
  //   nomenu: true,
  //   child: []
  // }
];

export default RouterBase;
