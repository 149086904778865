import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@/store';
import { commonApi } from '@/services';
interface AppState {
  industry: any;
  errMsg: string;
}

const initialState: AppState = {
  industry: [], // 全部行业
  errMsg: ''
};

export const appSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIndustry: (state, action: PayloadAction<string>) => {
      state.industry = action.payload;
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
    },
  }
});

export const {
  setIndustry,
  setErrMsg,
} = appSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const getCommonIndustry =
  (): AppThunk =>
  async (dispatch) => {
    try {
      const { dataList } = await commonApi.GetIndustryTree();
      const options:any = []
      if (dataList) {
        Object.keys(dataList).forEach(key => {
          options.push({ label: dataList[key], key: key, value: key })
        })
      }
      dispatch(setIndustry(options));
    } catch (err: any) {
      dispatch(setErrMsg(err.toString()));
    }
  };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectIndustry = (state: RootState) => state.common.industry;

export default appSlice.reducer;
