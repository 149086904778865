import { AppPost, AppGet } from '@/utils/request';

import { DefaultCompaniesList, SwIndustryTreeList } from './types';

import { homeApi } from '@/config/api';
//违约主体
export function GetDefaultCompanies(params: any) {
  return AppGet<DefaultCompaniesList>('/bond/getDefaultCompanies', params);
}
//申万行业
export function GetSwIndustryTree(params: any) {
  return AppGet<SwIndustryTreeList>('/common/swIndustryParentTree', params);
}
//违约债券
export function GetDefaultBonds(params: any) {
  return AppGet<DefaultCompaniesList>('/bond/getDefaultBonds', params);
}

//违约分布
export function GetDefaultGeographicalDistribution(params: any) {
  return AppGet<DefaultCompaniesList>('/bond/getDefaultGeographicalDistribution', params);
}

//最新违约债券

export function GetNewestBondDefaultList(params: any) {
  return AppGet<DefaultCompaniesList>('/market/getNewestBondDefaultList', params);
}

//首次违约主体

export function GetFirstTimeDefaultCompany(params: any) {
  return AppGet<DefaultCompaniesList>('/bond/getFirstTimeDefaultCompany', params);
}

//历年违约统计

export function GetAnnualDefaultStatistic(params: any) {
  return AppGet<DefaultCompaniesList>('/bond/getAnnualDefaultStatistic', params);
}
