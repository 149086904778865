import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, type FormProps, Input, message } from 'antd';
import { PASSWORD_REGEXP, PASSWORD_TIPS } from '@/utils/constant'
import { appApi } from '@/services'
import style from './index.module.less';

type FieldType = {
  curPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  showPwd?: string;
};



const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log('Failed:', errorInfo);

};
// 修改密码

const ModifyPwd: React.FC = () => {
  const values = {
    curPassword: '',
    password: '',
    confirmPaddword: '',
  }
  const [visible, setVisible] = useState<any>(false);
  const [form] = Form.useForm();
  const [disableButton, setDisableButton] = useState<any>(false);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // 递减秒数
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        // 当秒数为0时，清除定时器
        setDisableButton(false)
        clearInterval(interval);
      }
    }, 1000);

    // 组件卸载时清除定时器
    return () => clearInterval(interval);
  }, [seconds]);
  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    setDisableButton(true)
    try {
      const { curPassword, newPassword, confirmPassword } = values
      const params: any = {
        curPassword,  // 原密码
        newPassword,  // 新密码
        confirmPassword// 确认密码
      }
      const res = await appApi.changePassword(params)
      if (res && res.return_code !== '0') {
        return message.error(res.return_msg)
      }
      form.resetFields();
      setSeconds(10)
      message.success('修改成功')
      // 重新登录
      // const { origin } = window.location
      // window.location.href = `${origin}/login`
    } catch (error) {
      console.log(error)
      setDisableButton(false)
    }
    // message.info('开发中，敬请期待！')
  };
  return <div className={style.modifyPwd}>
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 5 }}
      labelAlign='left'
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 350 }}
      initialValues={{ ...values }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      requiredMark='optional'
    >
      <Form.Item<FieldType>
        label="原密码"
        name="curPassword"
        colon={false}
        rules={[{ required: true, message: '请输入原密码' }, { max: 15, min: 6, message: '长度为6-15位字符' }]}
      >
        {visible ? <Input autoComplete="new-password" placeholder='请输入原密码' /> : <Input.Password autoComplete="new-password" visibilityToggle={false} placeholder='请输入原密码' />}
      </Form.Item>

      <Form.Item<FieldType>
        label="新密码"
        name="newPassword"
        colon={false}
        // dependencies={['confirmPaddword']}
        rules={[{ required: true, message: '请输入新密码' },
        { pattern: PASSWORD_REGEXP, message: PASSWORD_TIPS },
          // ({ getFieldValue }) => ({
          //   validator(_, value) {
          //     if (!value || getFieldValue('confirmPaddword') === value) {
          //       return Promise.resolve();
          //     }
          //     return Promise.reject(new Error('两次新密码不一致!'));
          //   },
          // }),
        ]}
      >
        {visible ? <Input autoComplete="new-password" placeholder='请输入新密码' /> : <Input.Password autoComplete="new-password" visibilityToggle={false} placeholder='请输入新密码' />}

      </Form.Item>
      <Form.Item<FieldType>
        label="确认密码"
        colon={false}
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[{ required: true, message: '请再次输入新密码' },
        { pattern: PASSWORD_REGEXP, message: PASSWORD_TIPS },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('确认密码与新密码不一致!'));
          },
        }),]}
      >
        {visible ? <Input autoComplete="new-password" placeholder='请确认新密码' /> : <Input.Password autoComplete="new-password" visibilityToggle={false} placeholder='请确认新密码' />}

      </Form.Item>

      <Form.Item<FieldType>
        // name="showPwd"
        valuePropName="checked"
        wrapperCol={{ offset: 5, span: 19 }}
      >
        <Checkbox checked={visible} onClick={() => setVisible(!visible)}>显示密码</Checkbox>
        <Button disabled={disableButton} type="primary" htmlType="submit" style={{ float: 'right', marginRight: '42px', marginTop: '-4px' }}>
          {
            disableButton == false ? '保存' : `保存成功！(${seconds})`
          }
        </Button>
      </Form.Item>

    </Form>
  </div>
}

export default ModifyPwd;
