import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';
import { selectRoutingAddress, setRoutingAddress, setCheckedTag } from '@/store/modules/app';

function JumpFunction({ children, val, click, Original, style }: any) {
  const RoutingAddress = useSelector(selectRoutingAddress);
  const dispatch = useDispatch();
  const Jump = async () => {
    if (click) {
      val = await click(val)
    }
    const data = _.cloneDeep(RoutingAddress);
    const flag = data.filter((v: any) => v.key == String(val.key));
    if (flag.length == 0) {
      const parameter = { name: val.name, key: String(val.key), detailly: val.detailly, type: val.type };
      console.log(parameter)
      data.push(parameter);
      dispatch(setCheckedTag(String(val.key)));
      dispatch(setRoutingAddress(data));
    } if (Original == true) {
      const parameter = { name: val.name, key: String(val.key), detailly: val.detailly, type: val.type };
      const dataList = []
      data.map((v: any) => {
        if (v.key == parameter.key) {
          dataList.push(parameter)
        } else {
          dataList.push(v)
        }
      })
      dispatch(setCheckedTag(String(val.key)));
      dispatch(setRoutingAddress(dataList));
    } else {
      dispatch(setCheckedTag(String(val.key)));
    }
  };
  return (
    <div onClick={Jump} style={{ display: 'inline-block', ...style }}>
      {children}
    </div>
  ); // 根据需要返回相应的内容
}
//上侧菜单联动组件
//传过来的参数都是必填项：
//name：为菜单名称；
//key: 为该菜单唯一值，不可重复
//detailly：当前传递过来的全部参数
//type：用于确定新增什么页面的标识，例如新增一个video播放页面type的参数就为"video"
//Original：如果需要在原页面进行变更而不需要新开页面，同时key值有与新页面等同的情况下，要给Original设置为true则可以对原页面进行替换，而不是新增一个页面
export default JumpFunction;
