import { AppPost, AppGet, AppDownload } from '@/utils/request';

import { IRegionalSpread } from './types';

// 企业或债券关键字查询
export function commonSearch(params: any) {
  return AppGet<any>('/common/commonSearch', params);
}

// 曲线打勾操作
export function selectedCurve(params: any) {
  return AppGet<any>('/interestRateSpread/selectedCurve', params);
}

//  创建组
export function createGroup(params: any) {
  return AppPost<any>('/interestRateSpread/createGroup', params);
}

// 重命名组合
export function renameGroup(params: any) {
  return AppGet<any>('/interestRateSpread/renameGroup', params);
}

// 删除组
export function deleteGroup(params: any) {
  return AppGet<any>('/interestRateSpread/deleteGroup', params);
}

//  创建曲线
export function createCurve(params: any) {
  return AppPost<any>('/interestRateSpread/createCurve', params);
}

//  编辑曲线
export function editCurve(params: any) {
  return AppPost<any>('/interestRateSpread/editCurve', params);
}

// 删除曲线
export function removeCurve(params: any) {
  return AppGet<any>('/interestRateSpread/deleteCurve', params);
}

// 曲线添加到组
export function moveToGroup(params: any) {
  return AppGet<any>('/interestRateSpread/moveToGroup', params);
}

// 查询交易日
export function tradeDateList(params: any) {
  return AppGet<any>('/interestRateSpread/tradeDateList', params);
}

// 我得曲线列表查询
export function curveList(params: any) {
  return AppGet<any>('/interestRateSpread/curveList', params);
}

//  不受利差曲线的时间控制的 利差变动表格数据
export function rateSpreadChange(params: any) {
  return AppGet<IRegionalSpread>('/interestRateSpread/rateSpreadChange', params);
}

//  利差图表（受时间控制）
export function rateSpreadChart(params: any) {
  return AppGet<any>('/interestRateSpread/chart', params);
}

//  excel导出
export function downloadSpread(params: any, callback?: any) {
  return AppDownload('/interestRateSpread/export', params, '', callback);
}

// 主体利差表格
export function entRateSpread(params: any) {
  return AppGet<any>('/interestRateSpread/entRateSpread', params);
}

// 个券利差表格
export function bondsRateSpread(params: any) {
  return AppGet<any>('/interestRateSpread/bondsRateSpread', params);
}