import React from 'react';
import style from './index.module.less';
import beian from '@/assets/imgs/beian.png';

function AppFooter() {
  return (
    <div className={style.appFooter}>
      <ul>
        <li></li>
        <li>
          <a style={{ color: "white" }} target="_blank" rel="noopener noreferrer" href="https://beian.miit.gov.cn/#/Integrated/index">
            Copyright © 2024 中诚信信用分析（北京）有限公司 ICP备案/许可证号：京ICP备2024063088号-1
          </a>
          <a
            href="https://beian.mps.gov.cn/#/query/webSearch?code=11010102007176"
            rel="noreferrer"
            target="_blank"
            style={{ color: "#93BEFF" }}
          >
            <img
              src={beian}
              alt=""
              style={{
                width: "12px",
                height: "auto",
                marginLeft: "10px",
                position: "relative",
                right: "2px",
                top: "1.5px"
              }} />
            京公网安备11010102007176
          </a>
        </li>
        <li></li>
      </ul>
    </div>
  );
}

export default AppFooter;
