import { AppPost, AppGet } from '@/utils/request';

import { ICheckLoginResponse, IGetUserInfoResponse, IGetPublicOpinListParams } from './types';

import { homeApi, globalApi } from '@/config/api';

// console.log('=====', homeApi);
export function getMenu(params: string) {
  return AppGet<ICheckLoginResponse>(homeApi.menu, params);
}

export function homeSearch(params: string) {
  return AppGet<IGetUserInfoResponse>(homeApi.search, params);
}

export function getPublicOpinList(params: IGetPublicOpinListParams) {
  return AppGet<any>(homeApi.getPublicOpinList, params);
}
// 首页全局搜索
export function commonSearch(params: any) {
  return AppGet<any>(globalApi.commonSearch, params);
}

// 获取常用功能列表
export function getInCommonUserList() {
  return AppGet<any>('/user/getInCommonUserList');
}

// 添加常用功能
export function editInCommonUse(params: any) {
  return AppGet<any>('/user/editInCommonUse', params);
}