import { AppPost, AppGet, AppDownload, AppPostDownload} from '@/utils/request';
import { ICheckLoginRequest, ICheckLoginResponse, IGetUserInfoResponse, IChangePasswordParam } from './types';
import {absProductApi, globalApi} from '@/config/api'

// 获取表产品分类下拉框options
export function getProductClass(data?: any) {
  return AppGet(absProductApi.productClass, data);
}

// 获取归集现金流
export function getCashflowList(data: any) {
  return AppPost(absProductApi.cashflowList, data);
}


// 选择全部 https://data.ccxa.cn/abs/durationAsset/allDealsId
export function getAllDealsId(data?: any) {
  return AppPost(absProductApi.allCashflowIds, data);
}


// 数据下载 https://data.ccxa.cn/abs/download/export
export function exportFile(data : any, fileName) {
  return AppPostDownload(absProductApi.absDownload, data, fileName);
}


