import { AppPost, AppGet, AppGetWW ,AppDownload} from '@/utils/request';

import {
  RegionDataList
} from './types';

import { homeApi } from '@/config/api';
//外部企业检索
//keyword
//page
export function getRankEntity(params: any) {
  return AppGet<RegionDataList>('/common/getRankEntity', params);
}
//1.发起风险初筛
//entName	string	是	康美药业股份有限公司	企业名称
//unCid	string	是	91445200231131526C	统一社会信用代码
//regNo	string	是	440000000006711	企业注册号

export function insertRiskScreen(params: any) {
  return AppGet<RegionDataList>('/riskScreen/insertRiskScreen', params);
}

//2.查看报告
//、uid	string	是	3536323857334484992	风险初筛报告uid
export function selectRiskReport(params: any) {
  return AppGet<RegionDataList>('/riskScreen/selectRiskReport', params);
}
//4.查询历史风险初筛记录
export function queryRiskScreen(params: any) {
  return AppGet<RegionDataList>('/riskScreen/queryRiskScreen', params);
}
//查询报告特定条目内容项目详情
export function selectRiskScreenDetails(params: any) {
  return AppGet<RegionDataList>('/riskScreen/selectRiskScreenDetails', params);
}
//5.查询风险初筛点数
export function getPoints(params: any) {
  return AppGet<RegionDataList>('/riskScreen/getPoints', params);
}
