import { AppPost, AppGet, AppDownload } from '@/utils/request';

import { Invest, List } from './types';

export function financialDemining_list(params: any, callback: any) {
  AppPost('/financialDemining/list', params, function (data: any) {
    callback(data);
  });
}

export function common_getRankEntity(params: any, callback: any) {
  AppGet('/common/getRankEntity', params, function (data: any) {
    callback(data);
  });
}
export function financialDemining_create(params: any) {
  return AppPost<any>('/financialDemining/create', params);
}
export function financialDemining_getInputData(params: any, callback: any) {
  AppPost('/financialDemining/getInputData?uid=' + params.uid, "", function (data: any) {
    callback(data);
  });
}
export function financialDemining_saveInputData(url: any, params: any, callback: any) {
  return AppPost('/financialDemining/saveInputData?uid=' + url, params, function (data: any) {
    callback(data);
  });
}
export function common_srmIndustryTree(params: any, callback: any) {
  AppGet('/common/srmIndustryTree', params, function (data: any) {
    callback(data);
  });
}

export function financialDemining_submit(params: any, data: any) {
  return AppPost<any>('/financialDemining/submit?uid=' + params, data);
}

export function financialDemining_getDeminingResult(params: any, callback: any) {
  AppPost('/financialDemining/getDeminingResult?uid=' + params, "", function (data: any) {
    callback(data);
  });
}
export function financialDemining_getReportData(params: any, callback: any) {
  return AppPost('/financialDemining/getReportData?uid=' + params.uid + '&year=' + params.year, "", function (data: any) {
    callback(data);
  });
}
// export function FocusModify(params: any, callback: any) {
//   AppPost('/invest/focusModify', params, function (data: any) {
//     callback(data);
//   });
// }
// export function getPoolInfo(params: any, callback: any) {
//   AppGet('/invest/poolInfo', params, function (data: any) {
//     callback(data);
//   });
// }