import { AppPost, AppGet } from '@/utils/request';

import { DefaultCompaniesList, SwIndustryTreeList } from './types';

import { homeApi } from '@/config/api';
//违约主体
export function GetRegionCompare(params: any) {
  return AppGet<DefaultCompaniesList>('/smartCityInvestment/getRegionCompare', params);
}
export function GetIndustryTree(params: any) {
  return AppGet<DefaultCompaniesList>('/common/industryTree', params);
}
//经营数据
export function GetOperRecords(params: any) {
  return AppGet<DefaultCompaniesList>('/operatingFinance/getOperRecords', params);
}

