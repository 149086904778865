import { AppPost, AppGet, AppDownload, AppPostDownload } from '@/utils/request';

import { QeList, IGetUserInfoResponse } from './types';

export function region_statistics(params: any) {
  return AppGet<any>('/financing/region/statistics', params);
}
export function region_trend(params: any) {
  return AppGet<any>('/financing/region/trend', params);
}
export function region_bonds(params: any) {
  return AppGet<any>('/financing/region/bonds', params);
}
export function region_enterprises(params: any) {
  return AppGet<any>('/financing/region/enterprises', params);
}

export function openPDF(params: any, ExcelName: any, callback?: any) {
  return AppDownload('/financing/region/excel', params, ExcelName, callback);
}

export function industry_statistics(params: any) {
  return AppGet<any>('/financing/industry/statistics', params);
}
export function industry_trend(params: any) {
  return AppGet<any>('/financing/industry/trend	', params);
}
export function industry_bonds(params: any) {
  return AppGet<any>('/financing/industry/bonds', params);
}
export function industry_enterprises(params: any) {
  return AppGet<any>('/financing/industry/enterprises', params);
}
export function industry_excel(params: any, ExcelName: any, callback?: any) {
  return AppDownload('/financing/industry/excel', params, ExcelName, callback);
}
export function ccxIndustryTree(params: any) {
  return AppGet<any>('/common/ccxIndustryTree', params);
}
// export function bondNewValuation_list(params: any) {
//   return AppPost<any>('/bondNewValuation/list', params);
// }
// export function downloadExcel(params: any, ExcelName: any, callback?: any) {
//   const e = AppPostDownload('/bondNewValuation/exportExcel', params, ExcelName, callback);
//   return e;
// }