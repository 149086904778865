import React from 'react';
import titleIcon from '@/assets/icon/title.svg';
import style from './index.module.less';

export interface ITitlePoprs {
  children: string; // 标题文本内容
  extra?: any; // 其他额外内容
  className?: string
}

// Title
const Title = (props: ITitlePoprs) => {
  const { extra } = props
  return <div className={[style.exchangeTitle, props.className].join(' ')}>
    <div className={style.title}>
      <img className={style.titleIcon} src={titleIcon} alt="" />
      {props.children}
    </div>
    {extra ? extra : null}

  </div>
}

export default Title;
