import React, { FC } from 'react';
import { ConfigProvider, Layout, theme } from 'antd';

// import  moment from 'moment'
// import 'moment/locale/zh-cn'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import zh_CN from 'antd/es/locale/zh_CN'; // 中文配置  npm install moment
dayjs.locale('zh-cn');
import PageFooter from '@/components/Footer';
import { useSelector } from 'react-redux';

import style from './index.module.less';

// moment.locale('zh-cn')
const { Header, Footer, Content } = Layout;
import { selectTheme } from '@/store/modules/app';
const LayoutSimple: FC = (props: any) => {
  const themeName = useSelector(selectTheme);
  // const {
  //   token: { colorBgContainer }
  // } = theme.useToken();
  return (
    <ConfigProvider
      theme={{
        // 1. 单独使用暗色算法
        algorithm: themeName === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm,
        // 2. 组合使用暗色算法与紧凑算法
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        token: {
          colorPrimary: '#1677ff',
          colorText: themeName === 'dark' ? '#fff' : '#000'
        }
      }}
      locale={zh_CN}
    >
      <Layout className={style.layoutBlank} style={{ minWidth: '1280px' }}>
        {/* <Header
          className={style.header}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: themeName === 'dark' ? '#000' : '#fff',
            marginBottom: '28px'
          }}
        > */}
        {/* <BlankHeader /> */}
        {/* </Header> */}
        <Content className={style.content}>{props.children}</Content>
        <Footer className={style.footer}>
          <PageFooter />
        </Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutSimple;
