import { AppPost, AppGet } from '@/utils/request';



export function GetIndustryTree(params?: any) {
  return AppGet<any>('/common/industryTree', params);
}

// 企业或债券关键字查询
export function commonSearch(params: any) {
  return AppGet<any>('/common/commonSearch', params);
}