export const WORLD_MAP_GEO = 'world_map_geo';

export const SELECTED_STATION_GROUP = 'selected_station_group';

export const RELOAD_DATA_RATE = 'reload_data_rate';

// 密码正则表达式：密码须为6-15位字母、数字或符号（如：！、@、#、$、%、&、_），不允许有空格，且字母、数字和符号至少包含两种
// export const PASSWORD_REGEXP2 = new RegExp(/((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/, "g")
export const PASSWORD_REGEXP = new RegExp(/^(?=(?:.*\d)(?:.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$).{6,15}$/, "g")
// export const PASSWORD_REGEXP3 = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d|.*[\W_])[a-zA-Z\d\W_]{6,15}$/, "g")
export const PASSWORD_TIPS = '密码须为6-15位字母、数字或符号（如：！、@、#、$、%、&、_），不允许有空格，且字母、数字和符号至少包含两种'
// 辅助色 
export const APP_COLORS:any  = ['#3c7eff', '#33CBBB', '#FFCD87', '#F76965', '#1DBCF6','#948CFF', '#7EE18B', '#F9925A', '#F97AB8', '#93BEFF']

// （利差echart可用）
// export const CHART_COLORS: any = ['#347bff', '#6ac8bb', '#ffc300', '#a5d63f', '#e06e56', '#6357ff', '#60d7a0', '#ffa546', '#F97AB8', '#93BEFF']
export const CHART_COLORS: any = ['#3c7eff', '#33CBBB', '#FFCD87', '#F76965', '#1DBCF6', '#948CFF', '#7EE18B', '#F9925A', '#F97AB8', '#93BEFF', '#4963D8',
 '#00A8CC', '#FEED98', '#FF9494', '#288DDF', '#6F8DE7', '#CAE5B6', '#FEE0C0', '#EDBEF0', '#E5DBEF']

