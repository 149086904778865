import app from './app';
import dashboard from './dashboard';
import counter from './counter';
import common from './common'; // 全局公用
import regionalSpread from './regionalSpread'; // 利差分析
import absProductAll from './absProductAll'; // abs产品大全
import absCashflow from './absCashflow'; // abs归集现金流
import absDurationAsset from './absDurationAsset'; // abs资产存续期
import absCreditMeasures from './absCreditMeasures'; // abs增信措施

export default {
  app,
  dashboard,
  counter,
  common,
  regionalSpread,
  absProductAll,
  absCashflow,
  absDurationAsset,
  absCreditMeasures
};
