import { AppPost, AppGet, AppDownload, AppPostDownload} from '@/utils/request';
import { ICheckLoginRequest, ICheckLoginResponse, IGetUserInfoResponse, IChangePasswordParam } from './types';
import {absProductApi, globalApi} from '@/config/api'

// 获取表产品分类下拉框options
export function getProductClass(data?: any) {
  return AppGet(absProductApi.productClass, data);
}

// 获取资产存续期列表
export function getList(data: any) {
  return AppPost(absProductApi.assetClaimsList, data);
}

// 获取表格列头
export function getFields(data: any) {
  return AppGet(absProductApi.productFieldList, data);
}

// 选择全部 https://data.ccxa.cn/abs/durationAsset/allDealsId
export function getAllDealsId(data?: any) {
  return AppPost(absProductApi.alldurationAssetIds, data);
}


// 数据下载 https://data.ccxa.cn/abs/download/export
export function exportFile(data : any, fileName) {
  return AppPostDownload(absProductApi.absDownload, data, fileName);
}

// 数据提取API02：获取静态池曲线数据 https://data.ccxa.cn/abs/durationAsset/staticPool
export function getStaticPool(data: any) {
  return AppPost(absProductApi.staticPool, data);
}

// // 比率图options ： https://data.ccxa.cn/abs/durationAsset/shortNameList
export function getShortNameList(data: any) {
  return AppPost(absProductApi.shortNameList, data);
}

// // 获取比率图曲线数据 https://data.ccxa.cn/abs/durationAsset/scaleDiagram
export function getScaleDiagram(data: any) {
  return AppPost(absProductApi.scaleDiagram, data);
}


// 不良类
export function getNonPerformList(data: any) {
  return AppPost(absProductApi.nonPerformList, data);
}

export function getAllids(data: any) {
  return AppPost(absProductApi.getAllids, data);
}