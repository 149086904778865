import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Button, Checkbox, Divider, Modal, Upload, message } from 'antd';
import type { GetProp, UploadProps, UploadFile } from 'antd';
import _ from 'lodash';
import avatar from '@/assets/icon/头像.svg';
import {
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  selectUserInfo,
  selectAvatar,
  getAvatar,
  selectRoutingAddress,
  selectCheckedTag
} from '@/store/modules/app';
import { getToken } from '@/utils/auth'
import UserInfo from './components/UserInfo';
import ModifyPwd from './components/ModifyPwd';
import UserPermission from './components/UserPermission';
import Title from '@/components/Title';
import { appApi } from '@/services'
import style from './index.module.less';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
// 用户个人中心 
const UserCenter = (props: any) => {
  const { open, onCancel } = props
  const userInfo: any = useSelector(selectUserInfo)
  // const avatar: any = useSelector(selectAvatar)
  const dispatch = useDispatch()
  const [active, setActive] = useState(0)
  const [checked, setChecked] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };
  const handleChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      console.log('upload:', info)
      getBase64(info.file.originFileObj as FileType, (url) => {
        setImageUrl(url);
      });
      const { file } = info
      if (file.response.return_code === '0') {
        dispatch(getAvatar())
        message.success(file.response.data)
      } else {
        message.error(file.response.return_msg)
      }
    }
  };
  // const handleUpload = async (info) => {
  //   console.log('file==', info)
  //   const formData = new FormData();
  //   formData.append('file', info.file);
  //   // You can use any AJAX library you like
  //   try {
  //     const res = await appApi.uploadAvatar(formData)
  //     console.log('upload==', res)
  //     // if (res.return_code === '0') {
  //     //         dispatch(getAvatar())
  //     //         message.success(res.data)
  //     //       } else {
  //     //         message.error(res.return_msg)
  //     //       }
  //   } catch (error) {
  //     console.log(error)
  //   }

  // };
  const tabList = [
    { name: '个人信息', children: <UserInfo userInfo={userInfo?.data} /> },
    { name: '个人权限', children: <UserPermission checked={checked} />, extra: <Checkbox checked={checked} onClick={() => setChecked(!checked)}>仅看开通</Checkbox> },
    { name: '修改密码', children: <ModifyPwd /> },
  ]

  const handleCancel = () => {
    setActive(0)
    onCancel()
  }

  return <Modal title="" open={open} onCancel={handleCancel} footer={null} wrapClassName={style.useModalWrapper} width='768px'>
    <div className={style.userInfo}>
      <div className={style.avatarWrapper}>
        {/* <Upload
          name="file"
          className={style.avatarUploader}
          // customRequest={handleUpload}
          action='/api/user/uploadPicture'
          headers={{
            'X-CCX-FITS-TOKEN': getToken()
          }}
          showUploadList={false}
          onChange={handleChange}
        >
          <span className={style.avatarBtn}>修改头像</span>
        </Upload> */}
        <Avatar
          size={68}
          src={avatar}
          icon={<UserOutlined />}
        />
      </div>
      <div className={style.userinfo}>
        <div>
          <span className={style.username}>{userInfo?.data?.name}</span>
          <span className={style.accountType}>{userInfo?.data?.roleList[0]?.name}</span>
        </div>
        <div>
          {userInfo?.data?.institution}
          {userInfo?.data?.department ? (<Divider type="vertical" className={style.divider} />) : ''}
          {userInfo?.data?.department}
        </div>
      </div>
    </div>
    <div className={style.userModalBody}>
      <ul className={style.modalLeft}>
        {tabList.map((item, index) => <li
          className={active === index ? style.active : ''}
          key={index}
          onClick={() => setActive(index)}
        >{item.name}</li>)}
      </ul>
      <div className={style.modalRight}>
        {tabList.map((item, index) => {
          if (index === active) {
            return <div style={{ height: '100%' }} key={index}><Title extra={item?.extra}>{item.name}</Title>{item.children}</div>
          }
        })}
      </div>
    </div>
  </Modal>
}

export default UserCenter;
