import { AppPost, AppGet, AppDownload } from '@/utils/request';

import { IRegionalSpread } from './types';


// 曲线打勾操作
export function selectedCurve(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/selectedCurve', params);
}

//  创建组
export function createGroup(params: any) {
  return AppPost<any>('/interestRateSpreadIndustry/createGroup', params);
}

// 重命名组合
export function renameGroup(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/renameGroup', params);
}

// 删除组
export function deleteGroup(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/deleteGroup', params);
}

//  创建曲线
export function createCurve(params: any) {
  return AppPost<any>('/interestRateSpreadIndustry/createCurve', params);
}

//  编辑曲线
export function editCurve(params: any) {
  return AppPost<any>('/interestRateSpreadIndustry/editCurve', params);
}

// 删除曲线
export function removeCurve(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/deleteCurve', params);
}

// 曲线添加到组
export function moveToGroup(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/moveToGroup', params);
}

// 查询交易日
export function tradeDateList(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/tradeDateList', params);
}

// 我得曲线列表查询
export function curveList(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/curveList', params);
}

//  不受利差曲线的时间控制的 利差变动表格数据
export function rateSpreadChange(params: any) {
  return AppGet<IRegionalSpread>('/interestRateSpreadIndustry/rateSpreadChange', params);
}

//  利差图表（受时间控制）
export function rateSpreadChart(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/chart', params);
}

//  excel导出
export function downloadSpread(params: any) {
  return AppDownload('/interestRateSpreadIndustry/export', params, '');
}

// 主体利差表格
export function entRateSpread(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/entRateSpread', params);
}

// 个券利差表格
export function bondsRateSpread(params: any) {
  return AppGet<any>('/interestRateSpreadIndustry/bondsRateSpread', params);
}