import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState, AppDispatch } from '@/store';
import { addCache, getCache } from '@/utils/cache';
import { WORLD_MAP_GEO, SELECTED_STATION_GROUP, RELOAD_DATA_RATE } from '@/utils/constant';
import {
  IGetMapGeoResponse,
  IStationDetailData,
  ISummary,
  IUserMapData
} from '@/services/modules/dashboard/types';
import { dashboardApi } from '@/services';

interface DashboardState {
  errMsg: string;
  mapGeo: IGetMapGeoResponse;
  summary: ISummary;
  systemInfo: Array<Array<string>>;
  detailInfo: IStationDetailData | null;
  showSetting: boolean;
  showCard: boolean;
  showStationName: boolean;
  stationGroup: Array<{
    value: string;
    label: string;
  }>;
  currentStationGroup: string;
  reloadDataRate: number;
  userMapData: Array<IUserMapData>;
}

// 先从缓存获取worldmap geo
// 如果缓存中存在worl_map_geo
// 则将其设为mapGeo的初值
const cachedWorldMapGeo = getCache<IGetMapGeoResponse>(WORLD_MAP_GEO);

const cachedSelectedStationGroup = getCache<string>(SELECTED_STATION_GROUP);

const cachedReloadDataRate = getCache<number>(RELOAD_DATA_RATE);

const initialState: DashboardState = {
  errMsg: '',
  mapGeo: (cachedWorldMapGeo as IGetMapGeoResponse) || null,
  systemInfo: [],
  detailInfo: null,
  showSetting: false,
  showCard: true,
  showStationName: true,
  stationGroup: [],
  currentStationGroup: cachedSelectedStationGroup || '',
  userMapData: [],
  summary: {
    userCount: {
      value: 0,
      unit: ''
    },
    stationsSourceCount: {
      value: 0,
      unit: ''
    },
    stationsDestCount: {
      value: 0,
      unit: ''
    },
    stationsTotalCount: {
      value: 0,
      unit: ''
    },
    exportRate: {
      value: 0,
      unit: ''
    },
    connectTotal: {
      value: 0,
      unit: ''
    },
    prodConnectTotal: {
      value: 0,
      unit: ''
    },
    prodUseTotal: {
      value: 0,
      unit: ''
    },
    prodAllTotal: {
      value: 0,
      unit: ''
    },
    starConnectTotal: {
      value: 0,
      unit: ''
    },
    starUseTotal: {
      value: 0,
      unit: ''
    },
    starAllTotal: {
      value: 0,
      unit: ''
    }
  },
  reloadDataRate: cachedReloadDataRate || 0
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers.It
    // doesn't actually mutate the state because it uses the Immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload;
    },
    clearErrMsg: (state) => {
      state.errMsg = '';
    },
    setWorldMapGeo: (state, action: PayloadAction<IGetMapGeoResponse>) => {
      state.mapGeo = action.payload;
    },
    setSystemInfo: (state, action: PayloadAction<Array<Array<string>>>) => {
      state.systemInfo = action.payload;
    },
    setDetailInfo: (state, action: PayloadAction<IStationDetailData>) => {
      state.detailInfo = action.payload;
    },
    setSummary: (state, action: PayloadAction<ISummary>) => {
      state.summary = action.payload;
    },
    setShowSetting: (state, action: PayloadAction<boolean>) => {
      state.showSetting = action.payload;
    },
    setShowCard: (state, action: PayloadAction<boolean>) => {
      state.showCard = action.payload;
    },
    setShowStationName: (state, action: PayloadAction<boolean>) => {
      state.showStationName = action.payload;
    },
    setStationGroup: (state, action: PayloadAction<Array<{ value: string; label: string }>>) => {
      state.stationGroup = action.payload;
    },
    setCurrentStationGroup: (state, action: PayloadAction<string>) => {
      state.currentStationGroup = action.payload;
      addCache(SELECTED_STATION_GROUP, action.payload);
    },
    setReloadDataRate: (state, action: PayloadAction<number>) => {
      state.reloadDataRate = action.payload;
      addCache(RELOAD_DATA_RATE, action.payload);
    },
    setUserMapData: (state, action: PayloadAction<Array<IUserMapData>>) => {
      state.userMapData = action.payload;
    }
  }
});

export const {
  setErrMsg,
  clearErrMsg,
  setWorldMapGeo,
  setSystemInfo,
  setDetailInfo,
  setSummary,
  setShowSetting,
  setShowCard,
  setShowStationName,
  setStationGroup,
  setCurrentStationGroup,
  setReloadDataRate,
  setUserMapData
} = dashboardSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const getWorldMapGeo = (): AppThunk => async (dispatch) => {
  try {
    const res = await dashboardApi.getMapGeo();
    dispatch(setWorldMapGeo(res));
    addCache(WORLD_MAP_GEO, res);
  } catch (err: any) {
    dispatch(setErrMsg(err));
  }
};

export const getStationData =
  (stationGroup: string): AppThunk =>
  async (dispatch) => {
    try {
      const { systemInfo, detailInfo } = await dashboardApi.getMapStation(stationGroup);
      dispatch(setSystemInfo(systemInfo));
      dispatch(setDetailInfo(detailInfo));

      const summary = await dashboardApi.getSummary(stationGroup);
      dispatch(setSummary(summary));

      // const { systemInfo, detailInfo, summary } = await dashboardApi.getMapStation(stationGroup);
      // dispatch(setSystemInfo(systemInfo));
      // dispatch(setDetailInfo(detailInfo));
      // dispatch(setSummary(summary));
    } catch (err: any) {
      dispatch(setErrMsg(err));
    }
  };

export const getUserMapData = (): AppThunk => async (dispatch) => {
  const userMapData = await dashboardApi.getUserMapData();
  dispatch(setUserMapData(userMapData));
};

export const getStationGroup = (): AppThunk => async (dispatch) => {
  try {
    const groupList = await dashboardApi.getStationGroup();
    dispatch(setStationGroup(groupList));
  } catch (err: any) {
    dispatch(setErrMsg(err));
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// 错误信息
export const selectErrMsg = (state: RootState) => state.dashboard.errMsg;
// 世界地图GEo
export const selectWorldMapGeo = (state: RootState) => state.dashboard.mapGeo;
// 系统信息
export const selectSystemInfo = (state: RootState) => state.dashboard.systemInfo;
// 详细信息
export const selectDetailInfo = (state: RootState) => state.dashboard.detailInfo;
// 汇总信息
export const selectSummary = (state: RootState) => state.dashboard.summary;
// 是否显示设置
export const selectShowSetting = (state: RootState) => state.dashboard.showSetting;
// 是否显示card
export const selectShowCard = (state: RootState) => state.dashboard.showCard;
// 是否显示站点名称
export const selectShowStationName = (state: RootState) => state.dashboard.showStationName;
// 站点分组列表
export const selectStationGroup = (state: RootState) => state.dashboard.stationGroup;
// 当前选中的站点分组
export const selectCurrentStationGroup = (state: RootState) => state.dashboard.currentStationGroup;
// 页面刷新频率
export const selectReloadDataRate = (state: RootState) => state.dashboard.reloadDataRate;
// 用户地图数据
export const selectUserMapData = (state: RootState) => state.dashboard.userMapData;

export default dashboardSlice.reducer;
