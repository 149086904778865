import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@/store';
import {absProductApi} from '@/services'
import { message } from 'antd';
interface AppState {
  productClass: any[];
  selectedDealIds: string[];
}

const initialState: AppState = {
  productClass: [], // 产品分类
  selectedDealIds: []
 
};

export const absProductSlice = createSlice({
  name: 'absProductAll',
  initialState,
  reducers: {
    setProductClass: (state, action: PayloadAction<any>) => {
      state.productClass = action.payload;
    },
    setSelectedDealIds: (state, action: PayloadAction<any>) => {
      let keys = []
      if (Array.isArray(action.payload)) {
        keys = Array.from(new Set([...state.selectedDealIds, ...action.payload]))
      } else {
        keys = Array.from(new Set([...state.selectedDealIds, action.payload]))
      }
      state.selectedDealIds = keys;
    },
    removeSelectedDealIds: (state, action: PayloadAction<any>) => {
      const keys = [...state.selectedDealIds]
      if (Array.isArray(action.payload)) { 
        action.payload.forEach(key => {
          keys.splice(keys.findIndex(item => key === item), 1)
        })
        
      } else {
        keys.splice(keys.findIndex(item => item === action.payload), 1)
      }
      state.selectedDealIds = keys;
    },
    clearSelectedAllIds: (state) => {
      state.selectedDealIds = [];
    },
    selectedAllIds: (state, action: PayloadAction<any>) => {
      state.selectedDealIds = action.payload;
    }
  }
});

export const getProductClass = (): any => {
  return async (dispatch) => {
    try {
      const res: any = await absProductApi.getProductClass();
      if (res.return_code !== '0') {
        return message.error(res.return_msg)
      }
      dispatch(setProductClass(res.data));
    } catch (err: any) {
      message.error(err)
    }
  }
}
  


export const {
  setProductClass, setSelectedDealIds,removeSelectedDealIds,  clearSelectedAllIds, selectedAllIds
} = absProductSlice.actions;


export default absProductSlice.reducer;
