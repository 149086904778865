// 添加缓存
export function addCache(key: string, val: any): void {
  if (
    Object.prototype.toString.call(val) === '[object Object]' ||
    Object.prototype.toString.call(val) === '[object Array]'
  ) {
    localStorage.setItem(key, JSON.stringify(val));
  } else {
    localStorage.setItem(key, val);
  }
}

// 读取缓存
export function getCache<T>(key: string): T | null {
  const temp = localStorage.getItem(key);
  if (temp) {
    try {
      return JSON.parse(temp) as T;
    } catch {
      return null;
    }
  } else {
    return null;
  }
}

// 删除缓存
export function delCache(key: string): void {
  localStorage.removeItem(key);
}
