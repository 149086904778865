import { AppPost, AppGet, AppGetWW, AppDownload } from '@/utils/request';

import {
  NonStandardRiskList,
  NewEventTypeList,
  PbInfo,
  CityInvestmentNetFinancing,
  AnalysisExpiration,
  EnterprisePlatformList,
  RegionDataList
} from './types';

import { homeApi } from '@/config/api';

//获取地图数据
export function GetMap(params: any, callback?: any) {
  return AppGetWW<NonStandardRiskList>('/areas_v3/bound/geojson', params, callback);
}
//非标风险商票逾期
export function GetNonStandardRiskList(params: any) {
  return AppGet<NonStandardRiskList>('/platform/nonStandardRisk', params);
}
//非标风险事件类型
export function GetNewEventType(params: any) {
  return AppGet<NewEventTypeList>('/platform/newEventType', params);
}
//区域舆情

export function GetPbInfo(params: any) {
  return AppGet<PbInfo>('/region/pbInfo', params);
}
//区域融资
//区域净融资
export function GetCityInvestmentNetFinancing(params: any) {
  return AppGet<CityInvestmentNetFinancing>('/chartAnalysis/cityInvestmentNetFinancing', params);
}
//到期分布
export function GetAnalysisExpiration(params: any) {
  return AppGet<AnalysisExpiration>('/chartAnalysis/analysisExpiration', params);
}
//城投信息
export function GetEnterprisePlatformList(params: any) {
  return AppGet<EnterprisePlatformList>('/platform/getEnterprisePlatformList', params);
}
//智慧城投区域年度
export function getRegionAnnualYear(params: any) {
  return AppGet<EnterprisePlatformList>('/platform/regionAnnualYear', params);
}
//1.智慧城投-地图信息
export function getRegionInfo(params: any) {
  return AppGet<EnterprisePlatformList>('/regionRank/regionInfo', params);
}
//区域指标年度列表          请求方法：get
export function GetRegionYears(params: any) {
  return AppGet<EnterprisePlatformList>('/smartCityInvestment/getRegionYears', params);
}

//区域代码数据
//common/regionTree
export function getRegionTree(params: any) {
  return AppGet<EnterprisePlatformList>('/common/regionTree', params);
}

//城投指标年度
export function getTear(params: any) {
  return AppGet<EnterprisePlatformList>('/platform/year', params);
}
//1.城投列表：get请求地址：platform/regionInformation
export function getRegionInformation(params: any) {
  return AppGet<EnterprisePlatformList>('/platform/regionInformation', params);
}
//区域信息
export function getRegionData(params: any) {
  return AppGet<RegionDataList>('/smartCityInvestment/getRegionData', params);
}
export function exportExcel(params: any, ExcelName: any, callback?: any) {
  const e = AppDownload('/platform/exportExcel', params, ExcelName, callback);
  return e;
}
export function cityInvestmentNetFinancingExpirationDetail(params: any) {
  return AppGet<RegionDataList>('/chartAnalysis/cityInvestmentNetFinancingExpirationDetail', params);
}
export function exportRegionData(params: any, ExcelName: any, callback?: any) {
  const e = AppDownload('/smartCityInvestment/exportRegionData', params, ExcelName, callback);
  return e;
}
export function nonExportExcel(params: any, ExcelName: any, callback?: any) {
  const e = AppDownload('platform/nonExportExcel', params, ExcelName, callback);
  return e;
}
