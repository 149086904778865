import { AppPost, AppGet, AppPreView, AppPostDownload } from '@/utils/request';

import { QeList, IGetUserInfoResponse } from './types';

export function getReportList(params: any) {
  return AppGet<any>('/researchReport/getResearchReports', params);
}
export function openPDF(params: any, callback?: any) {
  return AppPreView('/minioFile/previewPDF', params, '', callback);
}
export function bondNewValuation_list(params: any) {
  return AppPost<any>('/bondNewValuation/list', params);
}
export function downloadExcel(params: any, ExcelName: any, callback?: any) {
  const e = AppPostDownload('/bondNewValuation/exportExcel', params, ExcelName, callback);
  return e;
}

export function depthReport_list(params: any) {
  return AppGet<any>('/depthReport/list', params);
}

export function openPDF2(params: any, callback?: any) {
  return AppPreView('/depthReport/downloadReport', params, '', callback);
}

export function getHotSpotsReport(params: any) {
  return AppGet<any>('/researchReport/getHotSpotsReport', params);
}