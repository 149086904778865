import { AppPost, AppGet, AppGetWW ,AppDownload} from '@/utils/request';

import {
  RegionDataList
} from './types';

import { homeApi } from '@/config/api';
// 获取投资者服务的多媒体视频信息
/*
method：post
参数名称	类型	是否必填	说明
meetingName	String	否	视频名称
beginDate	String	否	格式yyyy-MM-dd HH:mm:ss，可以根据该时间来做增量抽取，若不填时间表示全量抽取
endDate  	String	否	格式yyyy-MM-dd HH:mm:ss
current	Integer	否	默认1
size	Integer	否	默认100

*/
export function getOnlineMediaData(params: any) {
  return AppGet<RegionDataList>('/external/getOnlineMediaData', params);
}
//获取投资者服务的多媒体URL
//mediaId	Integer	是	多媒体视频信息的id
export function getOnlineMediaUrl(params: any) {
  return AppPost<RegionDataList>('/external/getOnlineMediaUrl?mediaId='+params.mediaId, params);
}
