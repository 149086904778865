import { AppPost, AppGet, AppDownload } from '@/utils/request';

import {
  List
} from './types';
//查看分组信息
export function getGroupInfo(params: any) {
  return AppGet<List>('/invest/groupInfo', params);
}
//组合池企业列表
export function getPoolInfo(params: any) {
  return AppGet<List>('/invest/poolInfo', params);
}
//新建组合池
export function groupCreate(params: any) {
  return AppGet<List>('/invest/groupCreate', params);
}
//组合池名称更新
export function groupModify(params: any) {
  return AppGet<List>('/invest/groupModify', params);
}
//出入池
export function modifyInfo(params: any) {
  return AppPost<List>('/invest/modifyInfo', params);
}
//删除
export function poolDrop(params: any) {
  return AppGet<List>('/invest/poolDrop', params);
}
//舆情预警
export function getPublicOpinList(params: any) {
  return AppGet<List>('/cabin/getPublicOpinList', params);
}
//QE评级预警
export function qeDowngradingWarning(params: any) {
  return AppGet<List>('/cockpit/qeDowngradingWarning', params);
}
//企业列表
export function getQyList(params: any) {
  return AppGet<List>('/investPool/getList', params);
}

//组合池批量添加
export function companyFilter(params: any) {
  return AppPost<List>('/invest/companyFilter', params);
}
export function investExport(params: any, ExcelName: any, callback?: any) {
  const e = AppDownload('/invest/export', params, ExcelName, callback);
  return e;
}

export function queryByGroup(params: any) {
  return AppGet<List>('/early-warning/queryByGroup', params);
}
